<template>
  <div class="row">
    <div class="col-4">
      <ul class="nav nav-tabs flex-column" style="padding-top: 80px">
        <li class="nav-item">
          <a
            class="nav-link"
            :class="{ active: mode == 'overview' }"
            @click="setMode('overview')"
            style="cursor: pointer"
            >Overview</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            :class="{ active: mode == 'device' }"
            @click="setMode('device')"
            style="cursor: pointer"
            >Devices</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" @click="setMode('logs')" style="cursor: pointer"
            >Logs</a
          >
        </li>
      </ul>
    </div>
    <div class="col-8" v-if="mode == 'overview'">
      <div class="row">
        <div class="col">
          <h1>Host Overview</h1>
        </div>
        <div class="col">
          <div
            class="btn-group"
            role="group"
            style="
              height: 34px;
              margin: 0px;
              margin-top: 12px;
              margin-left: 172px;
            "
          >
            <button
              class="btn btn-outline-warning"
              type="button"
              style="margin-right: 18px"
              @click="edit = true"
              v-if="!edit"
            >
              Edit</button
            ><button
              class="btn btn-outline-primary"
              type="button"
              style="margin-right: 18px"
              @click="update"
              v-else
            >
              Save
            </button>

            <button
              class="btn btn-danger"
              type="button"
              v-if="!edit"
              data-toggle="modal"
              data-target="#deleteHostModal"
            >
              Delete
            </button>
            <button
              class="btn btn-danger"
              type="button"
              @click="edit = false"
              v-else
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div
            class="row border-bottom"
            style="/*border-bottom: 50px;*/ /*border-style: dotted;*/"
          >
            <div class="col">
              <div class="form-group">
                <label>Name</label><br />
                <h3 v-if="!edit">{{ host.name }}</h3>
                <input
                  type="text"
                  :placeholder="host.name"
                  v-model="newName"
                  v-else
                />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label>Status</label>
                <h3>
                  <i
                    class="fa fa-dot-circle-o"
                    :class="{
                      online: host.status == 'ONLINE',
                      offline: host.status == 'OFFLINE',
                      unknown: host.status == 'UNKNOWN',
                    }"
                  ></i
                  >&nbsp;{{ host.status }}
                </h3>
              </div>
            </div>
          </div>
          <div class="row border-bottom">
            <div class="col">
              <div class="form-group">
                <label>Host ID</label>
                <h3>{{ host.host_id }}<br /></h3>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label>Host Type</label>
                <h3>{{ host.host_type }}</h3>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label>Host KEY</label>
                <h3 v-if="showKey">{{ host.host_key }}</h3>
                <h3 v-else @click="showKey_f">CLICK TO REVEAL<br /></h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-8" v-if="mode == 'device'">
      <div class="row">
        <div class="col">
          <h1 @click="test">Devices</h1>
        </div>
        <div class="col">
          <button
            class="btn btn-outline-success"
            type="button"
            data-toggle="modal"
            data-target="#addDeviceModal"
            style="float: right; margin-top: 10px"
            @click="setAdding"
            v-if="!edit"
          >
            Add Device
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <table class="table table-hover" v-if="host.devices.length != 0">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">ID</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(device, index) in host.devices" :key="device">
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ device.name }}</td>
                <td>{{ device.device_id }}</td>
                <td>
                  <router-link
                    :to="{
                      name: 'device_detail',
                      params: { device_id: device.device_id },
                    }"
                    class="btn btn-dark"
                    >View</router-link
                  >
                </td>
              </tr>
            </tbody>
          </table>

          <p v-else>
            No Devices attached to this host. Click Add Devices to add a new
            device to this host!
          </p>
        </div>
      </div>
    </div>
  </div>
  <addDeviceModal :host-id="host.host_id" />
  <deleteHostModal :host-name="host.name" :host-id="host.host_id" />
</template>

<script>
import addDeviceModal from "@/components/newDeviceModal";
import deleteHostModal from "@/components/deleteHostModal";
export default {
  name: "hostDetail",
  components: {
    addDeviceModal,
    deleteHostModal,
  },
  data: () => ({
    showKey: false,
    edit: false,
    mode: "overview",
    newName: "",
  }),
  methods: {
    showKey_f() {
      this.showKey = true;
    },
    setMode(mode) {
      this.mode = mode;
    },
    update() {
      this.$store.dispatch("updateHost", {
        host_id: this.host.host_id,
        new_name: this.newName,
      });
    },
    reload() {
      this.$store.dispatch("fetchCurrentHost", {
        host_id: this.$route.params.host_id,
      });
    },
    setAdding() {
      this.$store.commit("setAddingDevice", true);
    },
  },
  computed: {
    host: {
      get() {
        return this.$store.getters.getCurrentHost;
      },
    },
  },
  mounted() {
    this.$store.dispatch("fetchCurrentHost", {
      host_id: this.$route.params.host_id,
    });
  },
};
</script>

<style lang="css">
.online {
  color: green;
}
.offline {
  color: red;
}
.unknown {
  color: gray;
}

.editInput {
  font-size: 30px;
}

input.editInput::placeholder {
  font-size: 30px;
}

.headerDivider {
  border-left: 1px solid #38546d;
  border-right: 1px solid #16222c;
  height: 80px;
  position: absolute;
  right: 249px;
  top: 10px;
}
</style>
