<template>
  <div class="modal shadow" role="dialog" tabindex="-1" id="addDeviceModal">
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add new device</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <form v-if="addingNewDevice">
            <div class="form-group">
              <label>Name</label>
              <input
                class="form-control"
                type="text"
                v-model="new_device.name"
              />
            </div>
            <div class="form-group">
              <label>Device Type</label
              ><select class="form-control" v-model="new_device.type">
                <option value="0">SELECT DEVICE TYPE</option>
                <option value="LIGHT">LIGHT</option>
              </select>
            </div>
            <div class="form-group">
              <label>PIN</label>
              <input
                class="form-control"
                type="number"
                v-model="new_device['capabilities'][0].pin"
              />
            </div>
          </form>
          <div v-else>
            {{ new_device_return }}
          </div>
        </div>
        <div class="modal-footer" v-if="addingNewDevice">
          <button
            class="btn btn-light"
            type="button"
            data-dismiss="modal"
            @click="cancel"
          >
            Cancel</button
          ><button class="btn btn-success" type="button" @click="addDevice">
            Add
          </button>
        </div>

        <div class="modal-footer" v-else>
          <button
            class="btn btn-success"
            type="button"
            data-dismiss="modal"
            @click="refreshList"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "addDeviceModal",
  props: ["hostId"],
  data: function () {
    return {
      new_device: {
        name: "",
        type: "",
        capabilities: [
          {
            name: "POWER",
            pin: 0,
          },
        ],
      },
      adding: true,
    };
  },
  computed: {
    host_id() {
      return this.hostId;
    },
    new_device_return() {
      return this.$store.getters.getNewDevice;
    },
    addingNewDevice: {
      set(value) {
        this.$store.commit("setAddingDevice", value);
      },
      get() {
        return this.$store.getters.getAddingDevice;
      },
    },
  },
  methods: {
    addDevice() {
      this.new_device["host_id"] = this.host_id;
      this.$store.dispatch("addDevice", this.new_device);
      this.addingNewDevice = false;
    },
    cancel() {
      this.new_device.name = "";
      this.new_device.type = "0";
      this.new_device["capabilities"][0].pin = 0;
    },
    refreshList() {
      this.$store.dispatch("fetchCurrentHost", {
        host_id: this.host_id,
      });
    },
  },
  mounted() {
    this.adding = true;
  },
};
</script>
