<template>
  <div>
    <div class="container" style="margin-top: 22px">
      <div class="row">
        <div class="col-4">
          <h3>
            <i class="fa fa-chevron-left"></i>
            <router-link
              :to="{
                name: 'host_detail',
                params: { host_id: ((device || {}).host || {}).host_id },
              }"
            >
              Back to {{ ((device || {}).host || {}).name }}</router-link
            >
          </h3>
          <ul class="nav nav-tabs flex-column" style="padding-top: 80px">
            <li class="nav-item">
              <a class="nav-link active" href="#">Overview</a>
            </li>
            <li class="nav-item"></li>
            <li class="nav-item">
              <a class="nav-link" href="#">Integrations</a>
            </li>
          </ul>
        </div>
        <div class="col-8">
          <div class="row">
            <div class="col">
              <h1>Device Overview</h1>
            </div>
            <div class="col">
              <button
                class="btn btn-danger"
                style="float: right; margin-top: 10px"
                data-toggle="modal"
                data-target="#deleteDeviceModal"
              >
                DELETE
              </button>
            </div>
          </div>
          <div class="row" style="margin-top: 50px">
            <div class="col">
              <div
                class="row border-bottom"
                style="/*border-bottom: 50px;*/ /*border-style: dotted;*/"
              >
                <div class="col">
                  <div class="form-group">
                    <label>Name</label>
                    <h3>{{ device.name }}</h3>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Type</label>
                    <h3>{{ device.type }}</h3>
                  </div>
                </div>
              </div>
              <div class="row border-bottom">
                <div class="col">
                  <div class="form-group">
                    <label>Device ID</label>
                    <h5>{{ device.device_id }}<br /></h5>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Host</label>
                    <h3>{{ ((device || {}).host || {}).name }}</h3>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="row" style="margin-top: 17px">
                    <div class="col">
                      <button
                        class="btn btn-primary float-right"
                        type="button"
                        @click="updateDevice"
                      >
                        <i class="fa fa-refresh"></i>
                      </button>
                    </div>
                  </div>
                  <div class="table-responsive" style="margin-top: 23px">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Capability</th>
                          <th>Current State</th>
                          <th>Pin</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="capability in device.capabilities"
                          :key="capability"
                        >
                          <td>{{ capability.name }}</td>
                          <td>{{ capability.state }}</td>
                          <td>{{ capability.pin }}</td>
                          <td>
                            <div class="btn-group" role="group">
                              <button
                                class="btn btn-success"
                                type="button"
                                @click="sendAction('POWER', 'TURN_ON')"
                              >
                                ON</button
                              ><button
                                class="btn btn-danger"
                                type="button"
                                style="margin-left: 10px"
                                @click="sendAction('POWER', 'TURN_OFF')"
                              >
                                OFF
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <deleteDeviceModal
      :device-name="device.name"
      :device-id="device.device_id"
      :host-id="((device || {}).host || {}).host_id"
    />
  </div>
</template>

<script>
import deleteDeviceModal from "@/components/deleteDeviceModal.vue";
export default {
  name: "DeviceDetail",
  components: {
    deleteDeviceModal,
  },
  methods: {
    async sendAction(name, action) {
      this.$store.dispatch("sendAction", {
        device_id: this.device.device_id,
        name: name,
        action: action,
      });
      // this.$forceUpdate();
      this.updateDevice();
    },
    updateDevice() {
      this.$store.dispatch("getDevice", {
        device_id: this.$route.params.device_id,
      });
    },
  },
  data: () => ({
    test: "hi",
  }),
  computed: {
    device() {
      return this.$store.getters.getCurrentDevice;
    },
    host_id() {
      return this.device["host"];
    },
  },
  mounted() {
    this.$store.dispatch("getDevice", {
      device_id: this.$route.params.device_id,
    });
  },
};
</script>
