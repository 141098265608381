<template>
  <div class="modal shadow" role="dialog" tabindex="-1" id="deleteHostModal">
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Delete Host</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <h4>Confirm DELETE</h4>
          <p>
            Are you sure you want to delete <strong>{{ hostName }}</strong
            >, this action is irreversible and will also delete All devices
            attached to this host.
          </p>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" type="button" data-dismiss="modal">
            CANCEL
          </button>

          <button
            class="btn btn-sm btn-danger"
            type="button"
            data-dismiss="modal"
            @click="deleteHost"
          >
            DELETE
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "deleteHostModal",
  props: ["hostName", "hostId"],
  data: () => ({}),
  computed: {},
  methods: {
    deleteHost() {
      this.$store.dispatch("deleteHost", this.hostId);
      this.$store.dispatch("getHosts");
      this.$router.push({
        name: "hosts",
      });
    },
  },
};
</script>
