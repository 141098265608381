import { createStore } from "vuex";
import router from "../router/index.js";
import { authHeader } from "../helper/authToken.js";
const baseURL = "https://api.aeroire.itspot.org/";

export default createStore({
  state: {
    hosts: [],
    new_host: "",
    adding_host: false,
    current_host: {},
    new_host_key: "",

    // DEVICE
    adding_device: false,
    current_device: {},
    new_device: null,

    // LOGIN
    incorrect_login: false,
    logged_in: false,
  },
  mutations: {
    setHosts(state, payload) {
      state.hosts = payload;
    },
    setNewHost(state, payload) {
      state.new_host = payload;
    },
    setAddingNewHost(state, payload) {
      state.adding_host = payload;
    },
    setCurrentHost(state, payload) {
      state.current_host = payload;
    },
    setNewHostKey(state, payload) {
      state.new_host_key = payload;
    },

    //  DEVICE
    setCurrentDevice(state, payload) {
      state.current_device = payload;
    },

    // LOGIN
    setIncorrectLogin(state, payload) {
      state.incorrect_login = payload;
    },
    setLoggedIn(state, payload) {
      state.logged_in = payload;
    },

    setNewDevice(state, payload) {
      state.new_device = payload;
    },
    setAddingDevice(state, payload) {
      state.adding_device = payload;
    },
  },
  actions: {
    async getHosts(state) {
      const hosts = await fetch(baseURL + "host/all", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader(),
        },
      });
      const status = await hosts.status;
      const host_data = await hosts.json();

      if (status == 401) {
        router.push("/login");
      }
      let a = 0;
      let return_list = [];
      for (let i = 0; i < Math.floor(host_data.length / 3) + 1; i++) {
        let temp = [];
        for (let j = 0; j < 3; j++) {
          if (a < host_data.length) {
            temp.push(host_data[a]);
            a++;
          } else {
            temp.push(0);
          }
        }
        return_list.push(temp);
      }
      state.commit("setHosts", return_list);
    },
    async fetchCurrentHost(state, params) {
      const response = await fetch(
        baseURL + "host" + "?host_id=" + params.host_id,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: authHeader(),
          },
        }
      );
      const response_data = await response.json();
      state.commit("setCurrentHost", response_data);
    },
    async addNewHost({ getters, commit }) {
      const response = await fetch(baseURL + "host", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader(),
        },
        body: JSON.stringify({ name: getters.getNewHost }),
      });
      const response_json = await response.json();
      commit("setNewHostKey", response_json.host_key);
      commit("setNewHost", "");
    },

    async getDevice(state, params) {
      fetch(baseURL + "device?device_id=" + params.device_id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response;
        })
        .then(function (response) {
          response.json().then(function (response) {
            state.commit("setCurrentDevice", response);
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    async login(state, params) {
      fetch(baseURL + "auth/token", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: params.username,
          password: params.password,
          grant_type: "password",
        }),
      })
        .then(function (response) {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response;
        })
        .then(function (response) {
          response.json().then(function (response) {
            localStorage.setItem("user_auth_token", response.access_token);
            state.commit("setLoggedIn", true);
            router.push("/hosts");
          });
        })
        .catch(function (error) {
          console.log(error);
          state.commit("setIncorrectLogin", true);
          state.commit("setLoggedIn", false);
        });
    },

    async updateHost({ dispatch }, params) {
      fetch(baseURL + "host", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader(),
        },
        body: JSON.stringify({
          host_id: params.host_id,
          name: params.new_name,
        }),
      })
        .then(function (response) {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });
      dispatch("fetchCurrentHost", {
        host_id: params.host_id,
      });
    },
    async sendAction({ dispatch }, params) {
      fetch(baseURL + "device/action", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          device_id: params.device_id,
          name: params.name,
          action: params.action,
        }),
      })
        .then(function (response) {
          if (!response.ok) {
            response.json().then(function (response) {
              throw Error(response);
            });
          }
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });
      dispatch("getDevice", {
        device_id: params.device_id,
      });
    },
    async addDevice({ commit }, device) {
      fetch(baseURL + "device", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(device),
      })
        .then(function (response) {
          response.json().then(function (response) {
            commit("setNewDevice", response);
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    async deleteHost(state, host_id) {
      fetch(baseURL + "host", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader(),
        },
        body: JSON.stringify({
          host_id: host_id,
        }),
      })
        .then(function (response) {
          if (response.statusText != "OK") {
            throw Error(response);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    async deleteDevice(state, device_id) {
      fetch(baseURL + "device", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          device_id: device_id,
        }),
      })
        .then(function (response) {
          if (response.statusText != "OK") {
            throw Error(response);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    async logout(state) {
      localStorage.setItem("user_auth_token", null);
      router.push("/login");
      state.commit("setIncorrectLogin", false);
      state.commit("setLoggedIn", false);
    },
  },
  modules: {},
  getters: {
    getHosts: (state) => state.hosts,
    getNewHost: (state) => state.new_host,
    getAddingNewHost: (state) => state.adding_host,
    getCurrentHost: (state) => state.current_host,
    getNewHostKey: (state) => state.new_host_key,
    getCurrentDevice: (state) => state.current_device,
    getIncorrectLogin: (state) => state.incorrect_login,
    getNewDevice: (state) => state.new_device,
    getAddingDevice: (state) => state.adding_device,
    getLoggedIn: (state) => state.logged_in,
  },
});
