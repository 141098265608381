<template>
  <div class="text-center" style="max-width: 500px; margin: auto">
    <h1 class="mt-4 font-weight-normal">Login</h1>
    <form>
      <div class="form-group">
        <input
          class="form-control"
          type="text"
          name="username"
          placeholder="Username"
          v-model="username"
        />
        <input
          class="form-control"
          type="password"
          name="password"
          placeholder="Password"
          style="margin-top: 5px"
          v-model="password"
        />
        <p style="color: var(--pink)" v-if="incorrectLogin">
          Incorrect Details
        </p>
        <button
          class="btn btn-primary"
          @click="login"
          type="button"
          style="margin-top: 10px"
        >
          Login
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "Login",
  data: () => ({
    username: null,
    password: null,
  }),

  methods: {
    login() {
      this.$store.dispatch("login", {
        username: this.username,
        password: this.password,
      });
    },
  },
  computed: {
    incorrectLogin() {
      return this.$store.getters.getIncorrectLogin;
    },
  },
};
</script>
