<template>
  <div class="row">
    <div class="col">
      <h1>My Hosts</h1>
    </div>
    <div class="col">
      <div
        class="btn-group"
        role="group"
        style="float: right; margin-top: 10px"
      >
        <button
          class="btn btn-outline-primary"
          type="button"
          style="margin-right: 10px"
          data-toggle="modal"
          data-target="#addHostModal"
          @click="setAdding"
        >
          Add Host
        </button>
        <button class="btn btn-warning" type="button" @click="updateHostList">
          Refresh
        </button>
      </div>
    </div>
  </div>
  <div
    class="row"
    v-for="group in host_groups"
    :key="group"
    style="margin-top: 15px"
  >
    <div class="col" v-for="host in group" :key="host.key">
      <hostCard :host="host" v-if="host != 0" />
    </div>
  </div>

  <addHostModal />
</template>

<script>
import hostCard from "@/components/hostCard";
import addHostModal from "@/components/newHostModal";

export default {
  name: "Hosts",
  components: {
    hostCard,
    addHostModal,
  },
  data: () => ({}),
  methods: {
    updateHostList() {
      this.$store.dispatch("getHosts");
    },
    setAdding() {
      this.adding_new_dev = true;
    },
  },
  computed: {
    host_groups() {
      return this.$store.getters.getHosts;
    },
    adding_new_dev: {
      get() {
        return this.$store.getters.getAddingNewHost;
      },
      set(value) {
        this.$store.commit("setAddingNewHost", value);
      },
    },
  },
  mounted() {
    this.$store.dispatch("getHosts");
  },
};
</script>
