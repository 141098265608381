<template>
  <div class="card" style="width: 300px">
    <div class="card-body">
      <h4 class="card-title">
        {{ host.name }}
        <span
          class="badge status-badge"
          :class="{
            'badge-success': host.status == 'ONLINE',
            'badge-danger': host.status == 'OFFLINE',
            'badge-secondary': host.status == 'UNKNOWN',
          }"
          >{{ host.status }}</span
        >
      </h4>
      <h6 class="text-muted card-subtitle">{{ host.host_type }}</h6>
      <p v-if="host.host_id == null">Host ID: NOT ASSIGNED</p>
      <p v-else>HOST ID: {{ host.host_id }}</p>
      <p v-if="host.host_id == null">KEY: {{ host.host_key }}</p>
      <span
        class="d-inline-block"
        tabindex="0"
        :title="message"
        style="width: 100%"
      >
        <button
          class="btn btn-info"
          :class="{
            disabled: host.host_id == null,
          }"
          :disabled="active"
          type="button"
          style="width: 100%"
          @click="navigateToDetail"
        >
          Details
        </button>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "hostCard",
  props: {
    host: Object,
  },
  methods: {
    navigateToDetail() {
      if (this.host.host_id != null) {
        this.$router.push({
          name: "host_detail",
          params: { host_id: this.host.host_id },
        });
      }
    },
  },
  computed: {
    active() {
      if (this.host.host_id == null) {
        return true;
      }
      return false;
    },
    message() {
      if (this.host.host_id == null) {
        return "Host setup incomplete, setup host with host key to proceed!";
      }
      return "Go to details";
    },
  },
};
</script>
