<template>
  <nav
    class="
      navbar navbar-light navbar-expand-md
      sticky-top
      bg-light
      navigation-clean-button
    "
    v-if="currentRoute != 'login'"
  >
    <div class="container">
      <button
        data-toggle="collapse"
        class="navbar-toggler"
        data-target="#navcol-1"
      >
        <span class="sr-only">Toggle navigation</span
        ><span class="navbar-toggler-icon"></span>
      </button>
      <router-link to="/" class="navbar-brand">AEROIRE</router-link>
      <div class="collapse navbar-collapse" id="navcol-1">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <router-link
              :to="{ name: 'hosts' }"
              class="nav-link"
              active-class="active"
              exact
              >Hosts</router-link
            >
          </li>
        </ul>
        <span class="navbar-text actions">
          <a
            class="btn btn-light action-button"
            type="button"
            @click="logout"
            v-if="loggedIn"
            >LOG OUT</a
          ></span
        >
      </div>
    </div>
  </nav>
  <div class="container">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "APP",
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
  computed: {
    loggedIn: {
      get() {
        return this.$store.getters.getLoggedIn;
      },
      set(value) {
        this.$store.commit("setLoggedIn", value);
      },
    },
    currentRoute() {
      return this.$route.name;
    },
  },
  created() {
    this.loggedIn = true;
  },
};
</script>
