import { createRouter, createWebHistory } from "vue-router";
import Hosts from "../views/Hosts.vue";
import hostDetail from "../views/HostDetail.vue";
import DeviceDetail from "../views/DeviceDetail.vue";
import Login from "../views/Login.vue";
const routes = [
  {
    path: "/",
    redirect: { name: "hosts" },
  },
  {
    path: "/hosts",
    name: "hosts",
    component: Hosts,
    meta: {
      title: "My Hosts",
    },
  },
  {
    path: "/host/:host_id",
    name: "host_detail",
    component: hostDetail,
    meta: {
      title: "Host",
    },
  },
  {
    path: "/device/:device_id",
    name: "device_detail",
    component: DeviceDetail,
    meta: {
      title: "Device",
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "Login",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const public_pages = ["/login"];
  const authRequired = !public_pages.includes(to.path);
  const loggedIn = localStorage.getItem("user_auth_token");

  if (authRequired && !loggedIn) {
    return next("/login");
  }

  next();
});

export default router;
