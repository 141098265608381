<template>
  <div class="modal shadow" role="dialog" tabindex="-1" id="addHostModal">
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add new host</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <form v-if="adding_new_dev">
            <div class="form-group">
              <label>Name</label
              ><input
                class="form-control"
                v-model="newDevName"
                placeholder="Name"
                onkeypress="return event.keyCode != 13;"
                v-on:keyup.enter="addNewDev"
              />
            </div>
            <div class="form-group">
              <button
                class="btn btn-primary"
                type="button"
                @click="addNewDev"
                style="float: right"
              >
                Add
              </button>
            </div>
          </form>

          <form v-else>
            <div class="form-group">
              <label>Host Key</label>
              <p>This is you host key, <strong>KEEP IT SECRET!</strong></p>
              <p>{{ new_host_key }}</p>
            </div>
            <div class="form-group">
              <button
                class="btn btn-success"
                type="button"
                data-dismiss="modal"
                style="float: right"
                @click="refreshList"
              >
                OK
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "addHostModal",
  data: () => ({}),
  computed: {
    devName() {
      return this.$store.getters.getNewHost;
    },
    newDevName: {
      get() {
        return this.$store.getters.getNewHost;
      },
      set(value) {
        this.$store.commit("setNewHost", value);
      },
    },
    adding_new_dev: {
      get() {
        return this.$store.getters.getAddingNewHost;
      },
      set(value) {
        this.$store.commit("setAddingNewHost", value);
      },
    },
    new_host_key() {
      return this.$store.getters.getNewHostKey;
    },
  },
  methods: {
    addNewDev() {
      this.$store.dispatch("addNewHost");
      this.adding_new_dev = false;
    },
    refreshList() {
      this.$store.dispatch("getHosts");
    },
  },
};
</script>
